import React from "react"
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub } from '@fortawesome/free-brands-svg-icons'

import Layout from '../components/layout'
import SEO from '../components/seo'
import SidebarDoc from '../components/SidebarDoc'

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html, tableOfContents } = markdownRemark


  return (
    <Layout>
      <Helmet>
        <body className="doc" />
      </Helmet>

      <SidebarDoc />
      
      <SEO title="Developers" keywords={['developer tools', 'developers', 'sdk']}
           description="The tools you need to build Proof of Process apps." />

      <main className="content" id="intro">
        <div id="search">
         <input type="text" placeholder="Search the docs..." />
        </div>

        <section className="doc">
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </section>
      </main>

      <aside id="navigation">
        <div>
          {frontmatter.src && <a href={frontmatter.src}>View in Github</a>}
          {frontmatter.src && <FontAwesomeIcon className="github" icon={faGithub} />}
          <strong>Contents</strong>
          <div
            dangerouslySetInnerHTML={{ __html : tableOfContents }}
          />
        </div>
      </aside>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      tableOfContents(
        pathToSlugField: "frontmatter.path"
        maxDepth: 3
      )
      frontmatter {
        path
        src
      }
    }
  }
`
